.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.home-container01 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  margin-top: var(--dl-space-space-sixunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0,0%,100%,.8);
}
.home-text {
  text-align: center;
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text01 {
  color: var(--dl-color-secondary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
}
.home-text02 {
  color: var(--dl-color-secondary-600);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
}
.home-container02 {
  display: flex;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-container03 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-image {
  width: 25%;
  object-fit: cover;
}
.home-image01 {
  width: 25%;
  object-fit: cover;
}
.home-image02 {
  width: 25%;
  object-fit: cover;
}
.home-image03 {
  width: 25%;
  object-fit: cover;
}
.home-image04 {
  top: 0px;
  left: auto;
  right: 0px;
  width: 50%;
  bottom: 0px;
  height: 80vh;
  position: absolute;
  object-fit: cover;
  object-position: right;
  border-bottom-left-radius: 10rem;
}
.home-features {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-container06 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.home-text06 {
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text07 {
  color: rgb(203, 12, 159);
  white-space: normal;
  text-decoration: none;
}
.home-text08 {
  max-width: 600px;
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text09 {
  color: rgb(103, 116, 142);
  display: inline;
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unitandahalfunit);
  margin-right: var(--dl-space-space-unitandahalfunit);
  flex-direction: row;
}
.home-container08 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image05 {
  width: 100%;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  object-fit: cover;
  margin-left: 3rem;
  margin-right: -3rem;
  border-radius: var(--dl-radius-radius-radius75);
}
.home-text10 {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  border-color: #cb0c9f;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.25rem;
  margin-bottom: 8rem;
  flex-direction: column;
}
.home-text11 {
  color: rgb(203, 12, 159);
  display: inline;
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-text13 {
  color: rgb(203, 12, 159);
  font-weight: 400;
  background-color: rgb(255, 255, 255);
}
.home-container09 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image06 {
  top: 0px;
  left: auto;
  width: 90%;
  bottom: auto;
  display: block;
  position: absolute;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  margin-top: -1.5rem;
  object-fit: cover;
  margin-left: 2rem;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-container10 {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: var(--dl-space-space-triplequarterunit);
  padding-right: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}
.home-image07 {
  width: 100%;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  margin-top: 8rem;
  object-fit: cover;
  margin-left: -1.5rem;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-container11 {
  width: 90%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.home-text15 {
  color: rgb(37, 47, 64);
  font-weight: 700;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-text17 {
  color: rgb(103, 116, 142);
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-text20 {
  color: rgb(103, 116, 142);
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-text23 {
  color: rgb(103, 116, 142);
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-testimonials {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 1;
  max-width: 1320px;
  align-items: flex-start;
  padding-top: 15rem;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: 15rem;
  justify-content: space-between;
}
.home-container13 {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-container14 {
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text24 {
  color: var(--dl-color-gray-white);
}
.home-text25 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text26 {
  display: inline;
  font-weight: 400;
  white-space: normal;
}
.home-text27 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text28 {
  display: inline;
  font-weight: 600;
  white-space: normal;
}
.home-text29 {
  color: var(--dl-color-gray-white);
}
.home-text30 {
  color: var(--dl-color-gray-white);
  opacity: 0.8;
}
.home-container15 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image08 {
  width: 40px;
  opacity: 0.5;
  object-fit: cover;
  transition: 0.3;
  border-radius: var(--dl-radius-radius-radius75);
}
.home-image08:hover {
  transform: scale(1.2);
}
.home-container16 {
  flex: 0 0 auto;
  width: 1px;
  height: 25px;
  display: flex;
  opacity: 0.8;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-image09 {
  width: 40px;
  opacity: 0.5;
  object-fit: cover;
  transition: 0.3;
  border-radius: var(--dl-radius-radius-radius75);
}
.home-image09:hover {
  transform: scale(1.2);
}
.home-container17 {
  flex: 0 0 auto;
  width: 1px;
  height: 25px;
  display: flex;
  opacity: 0.8;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-image10 {
  width: 40px;
  object-fit: cover;
  transition: 0.3;
  border-radius: var(--dl-radius-radius-radius75);
}
.home-image10:hover {
  transform: scale(1.2);
}
.home-logos {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container18 {
  flex: 0 0 auto;
  display: flex;
  min-width: 400px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-triplequarterunit);
  flex-direction: column;
}
.home-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-container20 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image11 {
  width: 100%;
}
.home-container21 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image12 {
  width: 100%;
}
.home-container22 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image13 {
  width: 100%;
}
.home-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-container24 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image14 {
  width: 100%;
  object-fit: cover;
}
.home-container25 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image15 {
  width: 100%;
}
.home-container26 {
  width: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image16 {
  width: 100%;
}
.home-container27 {
  flex: 0 0 auto;
  width: 50%;
  height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container28 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image17 {
  width: 100%;
}
.home-container29 {
  width: 58px;
  height: 58px;
  padding: var(--dl-space-space-unit);
  align-self: flex-end;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  background-color: var(--dl-color-gray-white);
}
.home-image18 {
  width: 100%;
  object-fit: cover;
}
.home-bottom-wave-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -5px;
  position: absolute;
  object-fit: cover;
}
.home-image19 {
  top: auto;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-top-wave-image {
  top: -5px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-contaier {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-container30 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container31 {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius75);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
}
.home-icon {
  fill: var(--dl-color-gray-800);
  width: 24px;
  height: 24px;
}
.home-text31 {
  margin-top: var(--dl-space-space-unitandahalfunit);
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-text32 {
  color: rgb(203, 12, 159);
  white-space: normal;
  text-decoration: none;
}
.home-text33 {
  color: var(--dl-color-secondary-600);
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text34 {
  font-weight: 700;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-text36 {
  color: rgb(103, 116, 142);
  display: inline;
  font-weight: 400;
  white-space: normal;
  background-color: rgb(255, 255, 255);
}
.home-container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container33 {
  display: flex;
  position: relative;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: var(--dl-radius-radius-radius1);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover;
  justify-content: center;
  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-working.jpg');
  background-position: center;
}
.home-container33:hover {
  transform: scale(1.04);
}
.home-container34 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,.4);
  border-radius: var(--dl-radius-radius-radius1);
}
.home-container35 {
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon2 {
  fill: var(--dl-color-gray-900);
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text37 {
  color: var(--dl-color-gray-white);
  max-width: 200px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text38 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-container36 {
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-divider {
  flex: 0 0 auto;
  width: 90%;
  height: 1px;
  display: flex;
  opacity: 0.25;
  margin-top: 4rem;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius75);
  margin-bottom: 4rem;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-container37 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container38 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-container39 {
  display: flex;
  position: relative;
  box-shadow: 0 8px 26px -4px hsla(0,0%,8%,.15),0 8px 9px -5px hsla(0,0%,8%,.06)!important;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sixunits);
  border-radius: var(--dl-radius-radius-radius1);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-size: cover;
  justify-content: center;
  background-image: url("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/coffee-shop.jpg");
  background-position: center;
}
.home-container39:hover {
  transform: scale(1.04);
}
.home-container40 {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,.4);
  border-radius: var(--dl-radius-radius-radius1);
}
.home-container41 {
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon4 {
  fill: var(--dl-color-gray-900);
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text41 {
  color: var(--dl-color-gray-white);
  max-width: 200px;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text42 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-tripleunit);
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-container01 {
    max-width: 960px;
  }
  .home-card {
    width: 100%;
  }
  .home-features {
    max-width: 960px;
  }
  .home-container05 {
    max-width: 960px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container06 {
    max-width: 80%;
  }
  .home-text08 {
    text-align: center;
  }
  .home-text10 {
    width: 50%;
  }
  .home-container12 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    justify-content: center;
  }
  .home-container13 {
    align-items: center;
  }
  .home-container14 {
    margin-right: 0px;
  }
  .home-logos {
    display: none;
  }
  .home-contaier {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container36 {
    width: 45%;
    margin-left: var(--dl-space-space-unit);
  }
  .home-container38 {
    width: 45%;
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .home-card {
    align-items: center;
  }
  .home-text02 {
    text-align: center;
    margin-right: 0px;
    padding-right: 0px;
  }
  .home-image {
    width: 40%;
  }
  .home-image01 {
    width: 40%;
  }
  .home-image02 {
    width: 40%;
  }
  .home-image03 {
    width: 40%;
  }
  .home-image04 {
    display: none;
  }
  .home-features {
    max-width: 720px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container05 {
    max-width: 720px;
  }
  .home-container07 {
    align-items: center;
    flex-direction: column;
  }
  .home-container08 {
    width: 80%;
  }
  .home-image05 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text10 {
    width: 70%;
    align-self: center;
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-image06 {
    display: none;
  }
  .home-container10 {
    width: 80%;
  }
  .home-image07 {
    display: none;
  }
  .home-container12 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .home-container30 {
    width: 80%;
  }
  .home-text35 {
    text-align: center;
  }
  .home-container32 {
    flex-direction: column;
  }
  .home-container36 {
    width: 80%;
    margin-top: var(--dl-space-space-fourunits);
    align-items: center;
    margin-left: 0px;
  }
  .home-divider {
    margin-top: var(--dl-space-space-doubleunit);
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container37 {
    margin-top: 0px;
    flex-direction: column;
  }
  .home-container38 {
    width: 80%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 320px) {
  .home-card {
    padding: var(--dl-space-space-unit);
  }
  .home-container02 {
    align-items: center;
    flex-direction: column;
  }
  .home-container03 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-features {
    width: 100%;
  }
  .home-container12 {
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-text35 {
    text-align: center;
  }
}
